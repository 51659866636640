.auth {
  .leftside{
    width: 60%;
 
  }

   .filled-basic-label{
    color: white !important;
   }
    .form{
      padding-top: 100px;
      display: flex;
      width: 44%;
      flex-direction: column;
      align-items: center;
 
      background-color:white;
  
  
      height: 100vh;
    
    }
 
    .Mask{
    
        background-color: #0d6efd !important;
        opacity: 0.1;
        width: 100%;
        height: 100vh;
        position: absolute;
     
    }
    .logo{
      display: block;
  margin-left: auto;
  margin-right: auto;
 padding-bottom: 5px;
 width: 150px;
 position: absolute;
 top: 10px;
 left: 25px;
    }
  & > div {
    min-height: 100vh;
    position: relative;
    // background: rgba(255, 255, 255, 0.9);

    .register {
      position: absolute;
      transform: translate(-50%, 0%);
      top: 20%;
      left: 50%;
      background: #fff;
      box-shadow: 0 0 10px gray;
    }
    input {
      color: black;
    //  border: 1px solid white ;
      //border-left: 4px solid rgb(53, 88, 199) !important;
      &.error {
        border-left: 4px solid rgb(201, 74, 36) !important;
      }
    }
  }
}
