// Your variable overrides
$sidebar-bg-color: #0065af;
$sidebar-color: white !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: black !default;
$submenu-bg-color: #3998e378 !default;
$submenu-bg-color-collapsed: #3998e378 !default;
$icon-bg-color: #0079d9(38, 36, 56, 0.9) !default;
$icon-size: 60px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
@import "~react-pro-sidebar/dist/scss/styles.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$sidebar-bg-color: #0079d9;
$submenu-bg-color: #3998e378;
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #0079d9;
$highlight-color: #0079d9;
$icon-bg-color: white;
$icon-size: 35px;
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.pro-icon{
  font-size: 30px;
  color:  #ffffff ;
}
.outlet{
  overflow-x: hidden;
}
 .pro-sidebar-content{
  
  //overflow: hidden;
 }
 .MuiCardContent-root{

  padding: 1px !important;
 }

 .addgroupe{
  padding: 15px !important;
 }
.title{
  font-family: Tajawal;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: right;
  color: #313131;
}
.MuiDataGrid-root {
  border: none !important;
  padding: 0;
  
}
.MuiDataGrid-columnHeaderTitle{
  font-family: Tajawal;
  font-size: 13px;
  font-weight: bolder !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: right;
  color: #5b6b7b;
}
.MuiDataGrid-columnHeaders{
  background-color: #f4f4f4;
  border: none !important;
 


}
.MuiDataGrid-cell{
  border:none !important;
 
}
.css-46bh2p-MuiCardContent-root{
  padding: 4px;
}
.MuiDataGrid-row{
  border-bottom: 1px solid rgb(189, 188, 188);
  margin-top: 1px;
  margin-bottom: 1px;
  max-height: 90px;
}
.logout{
  width: 50px !important;
  height: 40px !important;
  margin-top: 54px !important;
    
 
  border-radius: 5px;
 
  background-color: #fa4d4d !important  ;
 
}
.config{
  
  width: 50px !important;
  height: 40px !important;
  margin-top: 54px !important;
    
 
  border-radius: 5px;

 
}
.addicon{
  color: #1cc3af !important;
}
.deleteicon{
 color: #fa4d4d !important;
}
.MuiButton-contained{
  width: 151.2px;
  height: 35px;
  background-color: #0065af  ;
  color: white !important;
  font-family: Almarai;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: right;
}
.modal-content{
  padding: 20px;
}
.loginbutton{
  width: 100%;
    margin-top: 15px;
    height: 51px;
 
}
.tpg2{
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 25%;
  font-family: Almarai;
  font-size: 55px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #0065af;
}
.tpg{
  margin-right: 5px;
  font-family: Almarai;
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #0065af;
}
 
.vl {
  border-left: 1px solid #8e938e85;
  height: 404px;
  margin-left: 5px;
  margin-top: 8px;
 
}
.v2 {
  border-left: 10px solid #8e938e87;
  height: 10px;
  margin-left: 5px;
  margin-top: 8px;
  margin-right: 5px;
 
}
.v3 {
  border-left: 1px solid #8e938e87;
  height: 25px;
  margin-left: 5px;
  margin-top: -2px;
  margin-right: 5px;
 
}
.userdiv{
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
 
  .MuiFormControl-root {
    margin: 3px;
    width: 100%;
  }
}
.footermodal{
  display: flex;
  flex-direction: row;
  justify-content: left;
 
  .save  {
    margin-left: 10px;

    width: 78.9px !important;
    height: 35px !important;
    margin: 5 15.7px 0 0 !important;
    padding: 12.3px 26px 8.8px 25.9px !important;
    border-radius: 5px !important;
    background-color: #0065af !important;
  }
  .cancel{
    width: 78.9px !important;
    height: 35px !important;
    margin: 0 0 0 15.7px !important;
    padding: 12.3px 26px 8.8px 25.9px !important;
    border-radius: 5px !important;
    background-color:#5b6b7b !important;
  }
}
.exchangeall{
  color: 
  #41c0e9 !important;
}
.eye{
  height: 35px !important;
  width: 35px !important;
  background-color: #1976d2 !important;
}
.searchuser{
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .addpelgrim{
    width: 18% !important;
    margin: 5px !important;
  }
}
.printbutton{
  width: 150.9px;
  height: 40px;
 margin: 10px !important;
 
  border-radius: 5px;
  background-color: #0065af;
  span{
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
  }
}
 .addpelgrim{
  width: 150.9px;
  height: 40px;
  margin-top: 54px !important;
    margin-left: 37px !important;
 
  border-radius: 5px;
  background-color: #0065af;
  span{
    font-family: Tajawal;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
  }
 }
.namestyle{
  width: 99px;
  height: 14px;
  margin: 0 14.6px 2px 67px;
  font-family: Tajawal;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: right;
  color: #0065af;
  margin-bottom: 5px
}
.FilesList{
  background-color: white;
    border: 1px solid;
    padding: 10px;
    margin-right: 10px;
    width: 37%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
#root {
  height: 100%;
}
.pro-item-content{
  font-size: 20px;
}
.app {
  
  height: 100%;
  display: flex;
  position: relative;
font-size: 24px;
  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // .sidebar-btn {
    //   padding: 1px 15px;
    //   border-radius: 40px;
    //   background: rgba(255, 255, 255, 0.05);
    //   color: #adadad;
    //   text-decoration: none;
    //   margin: 0 auto;
    //   height: 35px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   span {
    //     margin-left: 5px;
    //     font-size: 13px;
    //   }
    //   &:hover {
    //     color: rgb(87,63,189);
    //   }
    // }
  }
  .logo-container {
    // background: #444;
    color: rgb(87, 63, 189);
    height: 60px;
    line-height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      padding: 0 20px;
    }
    .btn-collapse {
      width: 80px;
      text-align: center;
      color: white;
      &:hover {
        color: rgb(87, 63, 189);
      }
    }
  }
  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }
  .sidebar-footer {
    padding: 10px;
    font-size: 12px;
    font-weight: 100;
    span {
      color: rgb(87, 63, 189);
    }
  }
  // -------------------------------------------------
  main {
    min-height: 100vh;
    background: #f5f6fa;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .outlet {
      padding: 4px;
      .container {
        background: #fff;
        -webkit-box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
        box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
      }
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .btn-toggle {
    display: none;
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}
header {
  display: flex;
  padding: 0px 2%;
  height: 60px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 10px 30px rgba(0, 0, 0, 0.08);
  &.rtl {
    direction: rtl;
    text-align: right;
  }
  .header-btns {
    position: relative;
    .header-btn {
      cursor: pointer;
      font-size: 25px;
      padding: 5px;
      display: inline-block;
      color: white;
      &:hover {
        animation: hovereffect 0.5s ease-in-out 0.5s;
        color: rgb(87, 63, 189);
      }
    }
  }
}
@keyframes hovereffect {
  0% {
    transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(0);
  }
  60% {
    transform: rotateZ(15deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

.filtercontainer{
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
}

 