@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: "Poppins", sans-serif;
}
button {
  outline: 0;
  cursor: pointer;
}
input:focus {
  outline: 0;
}
hr {
  background-color: #eee;
  border: 0 none;
  height: 1px;
  margin: 7px 0;
}
.css-1ss2ei0-MuiContainer-root{
  max-width: 100% !important;
}
.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
  &.green {
    color: #fff;
    background: #13af6e;
  }
  &.blue {
    color: #fff;
    background: #2636c4;
  }
}

.p-10 {
  padding: 10px;
}
.p-5 {
  padding: 5px;
}

// inline
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}
.tac {
  text-align: center;
}
// font weight
.f-100 {
  font-weight: 100;
}
.f-200 {
  font-weight: 200;
}
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}
.f-900 {
  font-weight: 900;
}
.dashicon{
  display: flex;
    flex-direction: column;
    align-items: center;
    svg{
      font-size: 100px;
color: #103996;
    }
}
.Card{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  background-image: none!important;
  overflow: hidden!important;
  border-radius: 12px!important;
  position: relative!important;
  z-index: 0;
  box-shadow: none!important;
  text-align: center!important;
  margin-top: 10px;
}
.Card1{
  color: rgb(6, 27, 100)!important;
  background-color: rgb(209, 233, 252)!important;
}
.Card2{
    color: rgb(4, 41, 122)!important;
    background-color: rgb(208, 242, 255)!important;
}
.Card3{
    color: rgb(4, 41, 122)!important;
    background-color: rgb(208, 242, 255)!important;
}

